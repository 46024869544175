// <a href="$imageLarge" data-bs-lightbox="produto" data-index="0"><img src="$thumbnail"></a>
// <a href="$imageLarge" data-bs-lightbox="servico"><img src="$thumbnail"></a>

var BsLightbox = (function(){
	var albuns = {};

 	var modalLightbox =  `
 		<div class="modal modal-dahora modal-dahora fade" id="modal-dahora" aria-hidden="true" role="dialog">
 			<div class="modal-dialog" role="document">
 				<div class="modal-content">
 					<div class="modal-body">
 						<div class="carousel carousel-fade slide" id="carousel-dahora">
 							<div class="carousel-inner">

 							</div>
 							<div class="carousel-controls">
 								<div class="clearfix">
 									<a class="icon icon-left-modal float-left" href="#carousel-dahora" data-slide="prev" role="button" title="Item Anterior"></a>
 									<a class="icon icon-right-modal float-right" href="#carousel-dahora" data-slide="next" role="button" title="Próximo Item"></a>
 								</div>
 							</div>
 						</div>
 					</div>
 				</div>
 			</div>
 		</div>
 	`;

 	$('.modal-dahora .carousel').carousel({
 		interval : 0 
 	});

 	$('body').append(modalLightbox);

	$('[data-bs-lightbox]').each(function(){

		var album = $(this).data('bs-lightbox');

		if(!albuns[album]){ // caso nao exista albuns['produtos']
			albuns[album] = [];
		}

		var imageLarge = $(this).attr('href');

		albuns[album].push(imageLarge);

	});


	$('[data-bs-lightbox]').click(function(event){
		event.preventDefault();

		var itemAtivo 		=  $(this).data('index');
		var albumAlvo 		=  $(this).data('bs-lightbox');
		var carosuelInner 	= $('.modal.modal-dahora .carousel-inner');

		carosuelInner.html('');


		for(var i=0;i< albuns[albumAlvo].length; i++){

			var img = albuns[albumAlvo][i];

			var item = '<div class="carousel-item">'+
				'<img src="'+img+'" alt="'+albumAlvo+'" class="img-fluid">'+
			'</div>';

			carosuelInner.append(item);
		}

		$('.modal.modal-dahora .carousel .carousel-inner > .carousel-item:nth-child('+itemAtivo+')').addClass('active');

		$('.modal.modal-dahora').modal('show');
	})
})();