(function(){

	var vigiaIsso = $('#topo').innerHeight();

	var toggle = $('.mbl-controls');

	$(window).scroll(function(){

		if($(this).scrollTop() > vigiaIsso){
			toggle.addClass('fixo');
		}else{
			toggle.removeClass('fixo');
		}

	});

})();