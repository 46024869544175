(function(){

	var collapseHover = $('.collapse-nao-full');

	collapseHover.each(function(){

		var isso = $(this);

		var esseBotaoCollapse = isso.find('.escondido-desktop');

		isso.hover(function(){

			isso.find('.collapse-custom').toggleClass('show')

		});

		isso.find('.escondido-desktop').click(function(){

			isso.toggleClass('active');

		});


	});

})();