(function(){

	var ativadores = $('.trocador-dahora');

	ativadores.click(function(){

		var alvoToggle = $('#topo');

		alvoToggle.toggleClass('ativado');

	});

})();