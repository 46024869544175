(function(){
	var videoIncrivel = $('.video-incrivel');
	videoIncrivel.click(function(){
		var htmlPronto = $(this).find('template').html();
		var bgVideo	   = $(this).data('video');
		$(this).html(htmlPronto);
		$(this).addClass('ativado');
		$(this).find('iframe').attr('src',bgVideo);
		$(this).attr('style','');
	});
})();