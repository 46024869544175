(function(){

	var linkDesabilitado = $('.link-debilitado');

	linkDesabilitado.click(function(){

		event.preventDefault();

	});

})();